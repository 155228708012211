import React, { useEffect } from 'react';

const Side = () => {
  useEffect(() => {
    const sidebarToggle = document.body.querySelector('#sidebarToggle');

    const handleToggle = (event) => {
      event.preventDefault();
      document.body.classList.toggle('sb-sidenav-toggled');
      localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
    };

    if (sidebarToggle) {
      sidebarToggle.addEventListener('click', handleToggle);

      return () => {
        sidebarToggle.removeEventListener('click', handleToggle);
      };
    }
  }, []);

  
};

export default Side;
