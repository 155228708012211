import React from "react";
import Home from "./components/Mains/Home";
import "./components/header/styles.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ClientManagement from "./components/Mains/ClientManagement";
import JobTicketList from "./components/Mains/JobTicketList";
import ClosedJobTicketList from "./components/Mains/ClosedJobTicketList";
import Login from "./components/Login/Login";
import Register from "./components/Login/Register";
import EditRegister from "./components/Login/EditRegister";
import Password from "./components/Login/Password";
import JobTicketDetails from "./components/Mains/jobticketdetails";
import NewJobTicket from "./components/Mains/NewJobTicket";
import "./App.css";
import Atest from "./components/header/Atest";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./components/header/scripts";

// import PrivateRoute from "./components/Context/PrivateRoute";
// import Test from "./components/Login/test";

function App() {
  // const [token, setToken] = useState();

  // if (!token) {
  //   return <Login setToken={setToken} />;
  // }
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Login />} />

          {/* <Route element={<PrivateRoute />}>
              <Route path="/test" element={<Test />} />
            </Route> */}
          <Route path="/atest" element={<Atest />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path="/editregistration" element={<EditRegister />}></Route>
          <Route path="/dashboard" element={<Home />}></Route>
          <Route path="/client-management" element={<ClientManagement />}></Route>
          <Route path="/Job-ticket-list" element={<JobTicketList />}></Route>
          <Route path="/close-job-ticket-list" element={<ClosedJobTicketList />}></Route>
          <Route path="/password" element={<Password />}></Route>
          <Route path="/jobticketdetails" element={<JobTicketDetails />}></Route>
          <Route path="/new-job-ticket" element={<NewJobTicket />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
