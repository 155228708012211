import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import Navbar from "../Navbar/Navbar";
import Footer from "../header/Footer";
import { postData, getData } from "../../FetchNodeServices";
import { useNavigate } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import { Link } from "react-router-dom";

const ClientManagement = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();
  const fetchUsers = async () => {
    let result = await getData("register/displayallusers");
    setList(result.result);
    setIsLoading(false);
  };
  useEffect(function () {
    fetchUsers();
  }, []);

  const handleOpen = (item) => {
    navigate("/editregistration", { state: { rowData: item } });
  };

  const handleDelete = async (id) => {
    let dresult = await postData("register/deletedata", { registerid: id });
    alert(dresult.result);
    fetchUsers();
  };

  const fillUsers = () => {
    return list.map((item) => {
      return (
        <tr key={item.registerid} value={item.registerid}>
          <td>
            {item.fname} &nbsp;
            {item.lname}
          </td>
          <td>{item.email}</td>
          <td>{item.status}</td> <td>{item.role}</td>
          <td className="action-icons text-center">
            <a onClick={() => handleOpen(item)} className="text-primary">
              <i className="fas fa-eye action-icon"></i>
            </a>
            <a onClick={() => handleOpen(item)} className="text-danger mx-2">
              <i className="fas fa-edit action-icon"></i>
            </a>
            <a className="text-warning" onClick={() => handleDelete(item.registerid)}>
              <i className="fas fa-trash-alt action-icon"></i>
            </a>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <Header />

      <div id="layoutSidenav">
        <Navbar />

        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid px-4">
              <h1 className="mt-4">Client Management</h1>
              <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Client List</li>
              </ol>
              <div className=" m-0">
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-table me-1"></i>Client List
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered text-center">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Role</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading && (
                            <tr>
                              <td colSpan={5}>
                                <InfinitySpin visible={true} width="200" color="#4fa94d" ariaLabel="infinity-spin-loading" />
                              </td>
                            </tr>
                          )}

                          {fillUsers()}
                          {/* <tr>
                            <td>John Doe</td>
                            <td>johndoe@example.com</td>
                            <td>
                              <small className="status-active">Active</small>
                            </td>
                            <td className="action-icons text-center">
                              <a href="/editregistration" className="text-primary">
                                <i className="fas fa-eye action-icon"></i>
                              </a>
                              <a href="/editregistration" className="text-danger mx-2">
                                <i className="fas fa-edit action-icon"></i>
                              </a>
                              <a href="#" className="text-warning">
                                <i className="fas fa-trash-alt action-icon"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Jane Doe</td>
                            <td>janedoe@example.com</td>
                            <td>
                              <small className="status-pending">Pending</small>
                            </td>
                            <td className="action-icons text-center">
                              <a href="/editregistration" className="text-primary">
                                <i className="fas fa-eye action-icon"></i>
                              </a>
                              <a href="/editregistration" className="text-danger mx-2">
                                <i className="fas fa-edit action-icon"></i>
                              </a>
                              <a href="#" className="text-warning">
                                <i className="fas fa-trash-alt action-icon"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Robert Smith</td>
                            <td>robert@example.com</td>
                            <td>
                              <small className="status-completed">Completed</small>
                            </td>
                            <td className="action-icons text-center">
                              <a href="/editregistration" className="text-primary">
                                <i className="fas fa-eye action-icon"></i>
                              </a>
                              <a href="/editregistration" className="text-danger mx-2">
                                <i className="fas fa-edit action-icon"></i>
                              </a>
                              <a href="#" className="text-warning">
                                <i className="fas fa-trash-alt action-icon"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>John Doe</td>
                            <td>johndoe@example.com</td>
                            <td>
                              <small className="status-active">Active</small>
                            </td>
                            <td className="action-icons text-center">
                              <a href="/editregistration" className="text-primary">
                                <i className="fas fa-eye action-icon"></i>
                              </a>
                              <a href="/editregistration" className="text-danger mx-2">
                                <i className="fas fa-edit action-icon"></i>
                              </a>
                              <a href="#" className="text-warning">
                                <i className="fas fa-trash-alt action-icon"></i>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>Jane Doe</td>
                            <td>janedoe@example.com</td>
                            <td>
                              <small className="status-pending">Pending</small>
                            </td>
                            <td className="action-icons text-center">
                              <a href="/editregistration" className="text-primary">
                                <i className="fas fa-eye action-icon"></i>
                              </a>
                              <a href="/editregistration" className="text-danger mx-2">
                                <i className="fas fa-edit action-icon"></i>
                              </a>
                              <a href="#" className="text-warning">
                                <i className="fas fa-trash-alt action-icon"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Robert Smith</td>
                            <td>robert@example.com</td>
                            <td>
                              <small className="status-completed">Completed</small>
                            </td>
                            <td className="action-icons text-center">
                              <a href="/editregistration" className="text-primary">
                                <i className="fas fa-eye action-icon"></i>
                              </a>
                              <a href="/editregistration" className="text-danger mx-2">
                                <i className="fas fa-edit action-icon"></i>
                              </a>
                              <a href="#" className="text-warning">
                                <i className="fas fa-trash-alt action-icon"></i>
                              </a>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ClientManagement;
