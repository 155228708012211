import axios from "axios";
// const ServerURL = "http://localhost:5000";
const ServerURL = "https://ticketportav1.development-review.net";

const getData = async (url) => {
  try {
    let response = await fetch(`${ServerURL}/${url}`, {
      method: "GET",
      mode: "cors",
      headers: { "content-type": "application/json;charset=utf-8" },
    });
    let result = await response.json();
    return result;
  } catch (e) {
    return null;
  }
};

const postData = async (url, body) => {
  try {
    let response = await fetch(`${ServerURL}/${url}`, {
      method: "POST",
      mode: "cors",
      headers: { "content-type": "application/json;charset=utf-8" },
      body: JSON.stringify(body),
    });
    let result = await response.json();
    return result;
  } catch (e) {
    return null;
  }
};

const postDataImage = async (url, body) => {
  try {
    let response = await axios.post(`${ServerURL}/${url}`, body, {
      headers: { "content-type": "multipart/form-data" },
    });
    let result = await response.data;
    return result;
  } catch (e) {
    return null;
  }
};

export { postData, postDataImage, getData, ServerURL };
