import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import Navbar from "../Navbar/Navbar";
import Footer from "../header/Footer";
import { getData } from "../../FetchNodeServices";

const Home = () => {
  const [clients, setClients] = useState(0);
  const [tickets, setTickets] = useState(0);

  const fetchData = async () => {
    var clients = await getData("register/displayallusers");
    setClients(clients.result.length);
    let tickets = await getData("jobticket/displayalltickets");
    setTickets(tickets.result.length);
  };
  useEffect(function () {
    fetchData();
  }, []);

  return (
    <div>
      <Header />
      <div id="layoutSidenav">
        <Navbar />

        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid px-4">
              <h1 className="mt-4">Dashboard</h1>
              <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
              <div className="row">
                <div className="col-xl-3 col-md-6">
                  <div className="card bg-primary text-white mb-4">
                    <div className="card-body">Total Clients</div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <span>{clients}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card bg-warning text-white mb-4">
                    <div className="card-body">Total Jobs Ticket</div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <span>{tickets}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card bg-success text-white mb-4">
                    <div className="card-body">Todays Total Jobs Ticket</div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <span>100</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card bg-danger text-white mb-4">
                    <div className="card-body">Total Jobs Ticket resolved</div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <span>1000</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <div className="card mb-4">
                    <div className="card-header">
                      <i className="fas fa-chart-area me-1"></i>
                      Job Tickets Month-Wise
                    </div>
                    <div className="card-body">
                      <canvas id="myAreaChart" width="100%" height="40"></canvas>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="card mb-4">
                    <div className="card-header">
                      <i className="fas fa-chart-bar me-1"></i>
                      New clients
                    </div>

                    <div className="card-body">
                      <canvas id="myBarChart" width="100%" height="40"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
