import { useState } from "react";
import { Link } from "react-router-dom";
import { postData } from "../../FetchNodeServices";
import { useNavigate } from "react-router-dom";

const Login = () => {
  let navigate = useNavigate();
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async () => {
    let result = await postData("login/chklogin", { emailid: emailId, password: password });
    if (result.result) {
      navigate("/dashboard");
    } else {
      alert("Invalid Login");
    }
  };

  return (
    <div className="bg-primary">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div className="card-header">
                      <h3 className="text-center font-weight-light my-4">Login</h3>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="inputEmail"
                            type="email"
                            placeholder="name@example.com"
                            onChange={(event) => setEmailId(event.target.value)}
                          />
                          <label htmlFor="inputEmail">Email address</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="inputPassword"
                            type="password"
                            placeholder="Password"
                            onChange={(event) => setPassword(event.target.value)}
                          />
                          <label htmlFor="inputPassword">Password</label>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                          <a className="small" href="/password">
                            Forgot Password?
                          </a>
                          <a className="btn btn-primary" onClick={handleSubmit}>
                            Login
                          </a>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer text-center py-3">
                      <div className="small">
                        <Link to="/register">Need an account? Sign up!</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js" crossOrigin="anonymous"></script>
        <script src="js/scripts.js"></script>
      </div>
    </div>
  );
};

export default Login;
