import React from "react";
import Side from "./scripts";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <div>
      <Side />
      <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
        <Link className="navbar-brand ps-3" to="/dashboard">
          Job Tickets
        </Link>

        <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!">
          <i className="fas fa-bars"></i>
        </button>

        <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0"></form>

        <ul className="navbar-nav me-3 me-lg-4">
          <li className="nav-item ">
            <a className="nav-link dropdown-toggle" role="button" id="navbarDropdown" aria-haspopup="true" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fas fa-user fa-fw"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <li>
                <a className="dropdown-item" href="#">
                  Setting
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Notifications
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
