import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import Navbar from "../Navbar/Navbar";
import Footer from "../header/Footer";
import { postDataImage, getData } from "../../FetchNodeServices";

function Newjobticket(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [priority, setPriority] = useState("");
  const [emails, setEmails] = useState([]);
  const [attachment, setAttachment] = useState({ bytes: "", filename: "" });

  const fetchUsers = async () => {
    let result = await getData("register/displayallusers");
    setEmails(result.result);
  };
  useEffect(function () {
    fetchUsers();
  }, []);

  const mails = emails.filter((item) => item.role === "developer" || item.role === "");

  const mailedUsers = mails.map((email) => {
    return email.email;
  });
  console.log(mailedUsers);

  const handleAttachmentChange = (event) => {
    setAttachment({
      bytes: event.target.files[0],
      filename: URL.createObjectURL(event.target.files[0]),
    });
  };

  const handleSubmit = async () => {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("date", date);
    formData.append("priority", priority);
    formData.append("attachment", attachment.bytes);
    formData.append("mails", mailedUsers);
    let result = await postDataImage("jobticket/addticket", formData);
    // alert(result.result);
  };

  return (
    <div>
      <Header />

      <div id="layoutSidenav">
        <Navbar />

        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid px-4">
              <h1 className="mt-4">Create Job Ticket </h1>
              <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active">New Job Ticket </li>
              </ol>
              <div className=" m-0">
                <div className="card p-4">
                  <div className="form">
                    {/* <form method="post" data-quillbot-parent="tDGPbgCFQD784p_ubSTPV"> */}
                    <form>
                      <div className="form-group mb-3">
                        <label htmlFor="">Title</label>
                        <input type="text" name="title" value={title} className="form-control" onChange={(event) => setTitle(event.target.value)} />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="">Description</label>
                        <input
                          className="form-control"
                          type="text"
                          value={description}
                          placeholder="Description"
                          onChange={(event) => setDescription(event.target.value)}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label className="d-block">Attachment</label>
                        <input name="attachment" id="" className="form-control" onChange={(event) => handleAttachmentChange(event)} multiple type="file" />
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="">Date</label>
                        <input className="form-control" type="date" value={date} placeholder="Date" onChange={(event) => setDate(event.target.value)} />
                      </div>

                      <div className="form-group mb-3">
                        <label>Priority</label>
                        <select className="form-control" value={priority} onChange={(event) => setPriority(event.target.value)}>
                          <option value="Low">Low</option>
                          <option value="Medium">Medium</option>
                          <option value="High">High</option>
                        </select>
                      </div>

                      <button className="btn btn-primary" onClick={() => handleSubmit()}>
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
}
export default Newjobticket;
