import React from "react";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <div id="layoutSidenav_nav">
      <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
        <div className="sb-sidenav-menu">
          <div className="nav">
            <Link className="nav-link" to="/dashboard">
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Dashboard
            </Link>
            <Link className="nav-link" to="/client-management">
              <div className="sb-nav-link-icon">
                <i className="fas fa-user"></i>
              </div>
              Client Management
            </Link>
            <Link className="nav-link" to="/new-job-ticket">
              <div className="sb-nav-link-icon">
                <i className="fas fa-pencil-square"></i>
              </div>
              Create Job Ticket
            </Link>
            <Link className="nav-link" to="/Job-ticket-list">
              <div className="sb-nav-link-icon">
                <i className="fas fa-book-open"></i>
              </div>
              Job Ticket List
            </Link>
            <Link className="nav-link" to="/close-job-ticket-list">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table"></i>
              </div>
              Closed Job Ticket List
            </Link>
          </div>
        </div>
        <div className="sb-sidenav-footer">
          <div className="small">Logged in as:</div>
          Admin
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
