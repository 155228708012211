import React, { useState, useEffect } from "react";
import { postDataImage, postData, ServerURL, getData } from "../../FetchNodeServices";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function EditRegister() {
  const location = useLocation();
  const [registerid, setRegisterid] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [status, setStatus] = useState("");
  const [npassword, setNPassword] = useState("");

  const fetchEditRegister = async () => {
    let result = location.state.rowData;
    setRegisterid(result.registerid);
    setFName(result.fname);
    setLName(result.lname);
    setEmailId(result.email);
    setStatus(result.status);
  };
  useEffect(function () {
    fetchEditRegister();
  }, []);

  const handleSubmit = async () => {
    var result = await postData("register/editdata", {
      registerid: registerid,
      fname: fName,
      lname: lName,
      email: emailId,
      password: npassword,
      status: status,
    });
    // alert(result.result);
  };

  return (
    <div className="bg-primary">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-7">
                  <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div className="card-header">
                      <h3 className="text-center font-weight-light my-4">Edit Details</h3>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <div className="form-floating mb-3 mb-md-0">
                              <input
                                className="form-control"
                                id="inputFirstName"
                                type="text"
                                value={fName}
                                onChange={(event) => setFName(event.target.value)}
                                placeholder="Enter your first name"
                              />
                              <label htmlFor="inputFirstName">First name</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating">
                              <input
                                className="form-control"
                                id="inputLastName"
                                type="text"
                                value={lName}
                                onChange={(event) => setLName(event.target.value)}
                                placeholder="Enter your last name"
                              />
                              <label htmlFor="inputLastName">Last name</label>
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-6">
                            <div className="form-floating mb-3">
                              <input
                                className="form-control"
                                id="inputEmail"
                                type="email"
                                value={emailId}
                                onChange={(event) => setEmailId(event.target.value)}
                                placeholder="name@example.com"
                              />
                              <label htmlFor="inputEmail">Email address</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating  mb-3">
                              <label>Status</label>
                              <select value={status} className="form-control" onChange={(event) => setStatus(event.target.value)}>
                                <option value="Active">Active</option>
                                <option value="Pending">Pending</option>
                                <option value="Completed">Completed</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <div className="form-floating mb-3 mb-md-0">
                              <input
                                className="form-control"
                                id="inputPassword"
                                type="password"
                                value={npassword}
                                onChange={(event) => setNPassword(event.target.value)}
                                placeholder="new password"
                              />
                              <label htmlFor="inputPassword"> New Password</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating mb-3 mb-md-0">
                              <input className="form-control" id="inputPasswordConfirm" type="password" placeholder="Confirm password" />
                              <label htmlFor="inputPasswordConfirm">Confirm Password</label>
                            </div>
                          </div>
                        </div>

                        <div className="mt-4 mb-0">
                          <div className="d-grid">
                            <Link to="/client-management" className="btn btn-primary btn-block" onClick={() => handleSubmit()}>
                              Submit Details
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js" crossOrigin="anonymous"></script>
      <script src="js/scripts.js"></script>
    </div>
  );
}

export default EditRegister;
