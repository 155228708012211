import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import Navbar from "../Navbar/Navbar";
import Footer from "../header/Footer";
import { getData } from "../../FetchNodeServices";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";
import { InfinitySpin } from "react-loader-spinner";

const ClosedJobTicketList = () => {
  const [list, setList] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();
  const fetchClosedTickets = async () => {
    let result = await getData("jobticket/displayallclosedtickets");
    setList(result.result);
    setIsLoading(false);
  };
  useEffect(function () {
    fetchClosedTickets();
  }, []);

  const handleOpen = (item) => {
    navigate("/jobticketdetails", { state: { rowData: item } });
  };

  const handleFilter = (key) => {
    if (sortBy === key) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(key);
      setSortDirection("asc");
    }
  };

  const sortedData = () => {
    if (!sortBy) return list;

    return [...list].sort((a, b) => {
      if (sortDirection === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
    });
  };

  const fillClosedJobTickets = () => {
    return sortedData().map((item) => {
      return (
        <tr key={item.ticketid} value={item.ticketid}>
          <td>{item.title}</td> <td>{item.description}</td> <td>{moment(item.date).format("DD/MM/YYYY")}</td>
          <td>{moment(item.date).format("DD/MM/YYYY")}</td>
          <td>
            <span className={`priority-btn priority-${item.priority}`}>{item.priority}</span>
          </td>
          <td className="action-icons text-center">
            <a onClick={() => handleOpen(item)} className="text-danger">
              <i className="fas fa-edit action-icon"></i>
            </a>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <Header />

      <div id="layoutSidenav">
        <Navbar />

        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid px-4">
              <h1 className="mt-4">Closed Job Ticket List</h1>
              <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>

                <li className="breadcrumb-item active">Closed Job List</li>
              </ol>
              <div className=" m-0">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between">
                    <div>
                      <i className="fas fa-table me-1"></i>
                      Closed Ticket List
                    </div>

                    <div className="filter">
                      <select onChange={(event) => handleFilter(event.target.value)}>
                        <option value="">Filter by</option>
                        <option value="title">Ticket number</option>
                        <option value="date">Date</option>
                        <option value="priority">Priority</option>
                        <option>Client</option>
                      </select>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered text-center">
                        <thead>
                          <tr>
                            <th>Title {sortBy === "title" && (sortDirection === "asc" ? "▲" : "▼")} </th>
                            <th>Description</th>
                            <th>Created Date {sortBy === "date" && (sortDirection === "desc" ? "▲" : "▼")}</th>
                            <th>Closed Date {sortBy === "date" && (sortDirection === "asc" ? "▲" : "▼")}</th>
                            <th className="text-center">Priority {sortBy === "priority" && (sortDirection === "asc" ? "▲" : "▼")}</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading && (
                            <tr>
                              <td colSpan={5}>
                                <InfinitySpin visible={true} width="200" color="#4fa94d" ariaLabel="infinity-spin-loading" />
                              </td>
                            </tr>
                          )}

                          {fillClosedJobTickets()}
                          {/* <tr>
                            <td>Job Title 1</td>
                            <td>Job Description 1</td>
                            <td>2023-01-01</td>
                            <td>2023-02-01</td>
                            <td className="text-center">
                              <span className="priority-btn priority-high">High</span>
                            </td>
                            <td className="action-icons text-center">
                              <a href="/jobticketdetails" className="text-danger">
                                <i className="fas fa-edit action-icon"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Job Title 2</td>
                            <td>Job Description 2</td>
                            <td>2023-01-01</td>
                            <td>2023-02-01</td>
                            <td className="text-center">
                              <span className="priority-btn priority-medium">Medium</span>
                            </td>
                            <td className="action-icons text-center">
                              <a href="/jobticketdetails" className="text-danger">
                                <i className="fas fa-edit action-icon"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Job Title 3</td>
                            <td>Job Description 3</td>
                            <td>2023-01-01</td>
                            <td>2023-02-01</td>
                            <td className="text-center">
                              <span className="priority-btn priority-low">Low</span>
                            </td>
                            <td className="action-icons text-center">
                              <a href="/jobticketdetails" className="text-danger">
                                <i className="fas fa-edit action-icon"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Job Title 1</td>
                            <td>Job Description 1</td>
                            <td>2023-01-01</td>
                            <td>2023-02-01</td>
                            <td className="text-center">
                              <span className="priority-btn priority-high">High</span>
                            </td>
                            <td className="action-icons text-center">
                              <a href="/jobticketdetails" className="text-danger">
                                <i className="fas fa-edit action-icon"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Job Title 2</td>
                            <td>Job Description 2</td>
                            <td>2023-01-01</td>
                            <td>2023-02-01</td>
                            <td className="text-center">
                              <span className="priority-btn priority-medium">Medium</span>
                            </td>
                            <td className="action-icons text-center">
                              <a href="/jobticketdetails" className="text-danger">
                                <i className="fas fa-edit action-icon"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Job Title 3</td>
                            <td>Job Description 3</td>
                            <td>2023-01-01</td>
                            <td>2023-02-01</td>
                            <td className="text-center">
                              <span className="priority-btn priority-low">Low</span>
                            </td>
                            <td className="action-icons text-center">
                              <a href="/jobticketdetails" className="text-danger">
                                <i className="fas fa-edit action-icon"></i>
                              </a>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ClosedJobTicketList;
