import React, { useState } from "react";
import { postDataImage } from "../../FetchNodeServices";

function Register(props) {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  const handleSubmit = async () => {
    let formData = new FormData();
    formData.append("fname", fName);
    formData.append("lname", lName);
    formData.append("email", emailId);
    formData.append("password", password);
    formData.append("role", role);
    let result = await postDataImage("register/registeruser", formData);
    // alert(result.result);
  };
  return (
    <div className="bg-primary">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-7">
                  <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div className="card-header">
                      <h3 className="text-center font-weight-light my-4">Create Account</h3>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <div className="form-floating mb-3 mb-md-0">
                              <input
                                className="form-control"
                                id="inputFirstName"
                                type="text"
                                value={fName}
                                onChange={(event) => setFName(event.target.value)}
                                placeholder="Enter your first name"
                              />
                              <label htmlFor="inputFirstName">First name</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating">
                              <input
                                className="form-control"
                                id="inputLastName"
                                type="text"
                                value={lName}
                                onChange={(event) => setLName(event.target.value)}
                                placeholder="Enter your last name"
                              />
                              <label htmlFor="inputLastName">Last name</label>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <div className="form-floating mb-3 mb-md-0">
                              <input
                                className="form-control"
                                id="inputEmail"
                                type="email"
                                value={emailId}
                                onChange={(event) => setEmailId(event.target.value)}
                                placeholder="name@example.com"
                              />
                              <label htmlFor="inputEmail">Email address</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating mb-3 mb-md-0">
                              <select class="form-select" aria-label="Default select example">
                                <option selected>Role</option>
                                <option value="developer">Developers</option>
                                <option value="client">Clients</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <div className="form-floating mb-3 mb-md-0">
                              <input
                                className="form-control"
                                id="inputPassword"
                                type="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                placeholder="Create a password"
                              />
                              <label htmlFor="inputPassword">Password</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating mb-3 mb-md-0">
                              <input className="form-control" id="inputPasswordConfirm" type="password" placeholder="Confirm password" />
                              <label htmlFor="inputPasswordConfirm">Confirm Password</label>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4 mb-0">
                          <div className="d-grid">
                            <a className="btn btn-primary btn-block" href="#" onClick={() => handleSubmit()}>
                              Create Account
                            </a>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer text-center py-3">
                      <div className="small">
                        <a href="/">Have an account? Go to login</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js" crossOrigin="anonymous"></script>
      <script src="js/scripts.js"></script>
    </div>
  );
}

export default Register;
