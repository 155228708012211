import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import Navbar from "../Navbar/Navbar";
import Footer from "../header/Footer";
import { postData, getData } from "../../FetchNodeServices";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
function JobTicketDetails() {
  const location = useLocation();
  const [ticketid, setTicketId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("");
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const [remark, setRemark] = useState("");
  const [emails, setEmails] = useState([]);

  const fetchUsers = async () => {
    let result = await getData("register/displayallusers");
    setEmails(result.result);
  };
  useEffect(function () {
    fetchUsers();
  }, []);

  const mails = emails.filter((item) => item.role === "client" || item.role === "");

  const mailedUsers = mails.map((email) => {
    return email.email;
  });
  console.log(mailedUsers);

  const fetchEditTicket = async () => {
    let result = location.state.rowData;
    setTicketId(result.ticketid);
    setTitle(result.title);
    setDescription(result.description);
    setPriority(result.priority);
    setStatus(result.status);
    setComment(result.comment);
    setRemark(result.remark);
  };
  useEffect(function () {
    fetchEditTicket();
  }, []);

  const handleSubmit = async () => {
    var result = await postData("jobticket/editticket", {
      ticketid: ticketid,
      title: title,
      description: description,
      priority: priority,
      status: status,
      comment: comment,
      remark: remark,
      mails: mailedUsers,
    });
    // alert(result.result);
  };

  return (
    <div>
      <Header />

      <div id="layoutSidenav">
        <Navbar />

        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid px-4">
              <h1 className="mt-4">Job Ticket Details</h1>
              <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item">
                  <a href="/job-ticket-list">Job Ticket List</a>
                </li>
                <li className="breadcrumb-item active">Ticket Detail</li>
              </ol>
              <div className=" m-0">
                <div className="card p-4">
                  <div className="form">
                    {/* <form method="post" data-quillbot-parent="tDGPbgCFQD784p_ubSTPV"> */}
                    <form>
                      <div className="form-group mb-3">
                        <label className="fw-bold" htmlFor="">
                          Priority
                        </label>
                        <span className={`priority-btn priority-${priority} mx-2`}>{priority}</span>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="">Title</label>
                        <input type="text" name="title" id="title" value={title} className="form-control" onChange={(event) => setTitle(event.target.value)} />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="">Description</label>
                        <input
                          type="text"
                          name="description"
                          id="title"
                          value={description}
                          className="form-control"
                          onChange={(event) => setDescription(event.target.value)}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label className="d-block">Attachment</label>
                        <a href="assets/img/error-404-monochrome.svg" target="_blank">
                          Preview in new Window
                        </a>
                      </div>

                      <div className="form-group mb-3">
                        <label>Status/Progress</label>
                        <select value={status} className="form-control" onChange={(event) => setStatus(event.target.value)}>
                          <option value="Active">Active</option>
                          <option value="Closed">Closed</option>
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label>Comments</label>
                        <input
                          type="text"
                          name="comments"
                          id="comments"
                          value={comment}
                          className="form-control"
                          onChange={(event) => setComment(event.target.value)}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label>Remarks by admin</label>
                        <textarea value={remark} className="form-control" onChange={(event) => setRemark(event.target.value)}></textarea>
                      </div>

                      <div className="form-group mb-3">
                        <label>Attachment by admin</label>
                        <input type="file" className="form-control" />
                      </div>

                      <Link to="/job-ticket-list">
                        <button className="btn btn-primary" onClick={() => handleSubmit()}>
                          Submit
                        </button>
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
}
export default JobTicketDetails;
